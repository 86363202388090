<template>
    <addEditMessageView ref="addEditMessageView"/>
    <addFileView ref="addFileView" />
    <el-main>
        <div class="teacher">
            <div class="app-table">
                <div class="app-body">
                    <div >
                        <el-form :inline="true" class="demo-form-inline" v-model="form">
                                        <el-form-item label="内容">
                                            <el-input
                                                    placeholder="请输入内容"
                                                    v-model="form.content"
                                            ></el-input>
                                        </el-form-item>
                            <el-form-item>
                                <el-button type="primary" @click="onSubmit"   @keyup.enter="searchEnter">搜索</el-button>
                                <el-button type="success" @click="add">添加</el-button>
                                <!-- <el-button type="warning" @click="exportExcel">导出报表</el-button> -->
                                <!-- <el-button type="danger" @click="importExcel">批量导入<el-icon class="el-icon--right"><Upload /></el-icon></el-button> -->
                            </el-form-item>
                        </el-form>
                    </div>
                    <el-table
                            size="small"
                            :data="list"
                            style="width: 100%"
                            border
                            :row-class-name="tableRowClassName"
                    >
                                <el-table-column prop="id" label="id"/>
                                <!-- <el-table-column prop="fromId" label="来自"/> -->
                                <!-- <el-table-column prop="toId" label="给谁"/> -->
                                <el-table-column prop="content" label="内容"/>
                                <el-table-column prop="createTime" label="创建时间"/>
                                <!-- <el-table-column prop="status" label="状态"/> -->
                        <el-table-column label="操作" width="140px">
                            <template style="display: block" #default="scope">
                                <el-button
                                        size="small"
                                        type="success"
                                        @click="editMessage(scope.row)"
                                >编辑
                                </el-button
                                >
                                <el-button
                                        size="small"
                                        type="danger"
                                        @click="deleteMessage(scope.row.id)"
                                >删除
                                </el-button
                                >
                            </template>
                        </el-table-column>
                        <template v-slot:empty>
                            <el-empty description="暂无数据"></el-empty>
                        </template>
                    </el-table>
                    <el-config-provider :locale="locale">
                        <el-pagination
                                :total="total"
                                :page-size="limit"
                                :page-sizes="[5, 10, 20, 30, 40, 50, 100]"
                                layout="total, sizes, prev, pager, next, jumper"
                                @size-change="changePageSize"
                                @current-change="changeCurrentPage"
                        >
                        </el-pagination>
                    </el-config-provider>
                </div>
            </div>
        </div>
    </el-main>
</template>
<script>
    import addEditMessageView from "@/components/addEditMessage.vue";
    import addFileView from "@/components/addExcelFile.vue";
    import {ElConfigProvider, ElMessageBox, ElMessage} from "element-plus";
    import zhCn from "element-plus/lib/locale/lang/zh-cn";
    import {httpService} from "@/utils/httpService";
    import {Picture as IconPicture,Upload } from "@element-plus/icons-vue";

    export default {
        name: "MessageListView",
        data() {
            return {
                type: 0,
                title: "",
                page: 0,
                pageSize: 10,
                total: 0,
                limit: 10,
                list: [],
                form: {
                    content: "",
                },
            };
        },
        components: {
            addEditMessageView,
            addFileView,
            IconPicture,
            ElConfigProvider,
            ElMessageBox,
            ElMessage,Upload
        },
        setup() {
            return {
                locale: zhCn,
            };
        },
        mounted() {
            this.getMessageList();
        },
        watch: {
            $route() {
                this.getMessageList();
            },
        },
        methods: {
            searchEnter: function (e) {
                // 使用 which 和 keyCode 属性来解决兼容问题
                var keyCode = window.event ? e.keyCode : e.which;
                var val = e.target.value;
                if (keyCode == 13 && val) {
                    this.getMessageList();
                }
            },
            add() {
                this.$refs.addEditMessageView.title = "添加消息表"
                this.$refs.addEditMessageView.personnelType = this.type
                this.$refs.addEditMessageView.dialogFormVisible = true;
            },
            editMessage(obj) {
                this.$refs.addEditMessageView.title = "修改信息"
                this.$refs.addEditMessageView.getMessageById(obj.id);
                this.$refs.addEditMessageView.dialogFormVisible = true;
            },
            deleteMessage(id) {
                ElMessageBox.confirm("确定要删除本条记录吗？", "提醒", {
                    confirmButtonText: "确定",
                    cancelButtonText: "取消",
                    type: "warning",
                })
                    .then(() => {
                        httpService("/api/message/deleteById", "id=" + id, "post").then(
                            (data) => {
                                if (data.code == 200) {
                                    ElMessage.success("删除成功");
                                    this.getMessageList();
                                } else {
                                    ElMessage.error("删除失败，请重试！");
                                }
                            }
                        );
                    })
                    .catch(() => {
                    });
            },
            reloadData(){
                this.page=1;
                this.getMessageList()
            },
            importExcel() {
                this.$refs.addFileView.dialogFormVisible = true;
                this.$refs.addFileView.title = "批量导入消息表";
                this.$refs.addFileView.uploadApiUrl = "/api/message/importExcel";
            },
            exportExcel() {
                httpService(
                    "/api/message/exportExcel",
                    {
                        "content": this.form.content,
                    },
                    "get"
                ).then((data) => {
                    if (data.code == 200) {
                        window.open(data.msg, '_blank')
                    }
                });
            },
            onSubmit() {
                this.getMessageList();
            },
            // 每页记录数改变，size：回调参数，表示当前选中的“每页条数”
            changePageSize(size) {
                this.pageSize = size;
                this.limit = size;
                this.getMessageList();
            },

            // 改变页码，page：回调参数，表示当前选中的“页码”
            changeCurrentPage(page) {
                this.page = page;
                this.getMessageList();
            },
            tableRowClassName({row, rowIndex}) {
                return "";
            },
            getMessageList() {
                httpService(
                    "/api/message/list/" + this.page + "/" + this.pageSize,
                    {
                        "content": this.form.content,
                    },
                    "get"
                ).then((data) => {
                    this.list = data.list;
                    this.total = data.total;
                    this.pageCount = data.pages;
                });
            },
        },
    };
</script>
<style scoped>
    .el-main {
        padding: 0px;
    }

    .teacher-content {
        width: 100%;
    }

    .app-table {
        background: #eff3f5;
        padding: 10px;
    }

    .app-search {
        padding-top: 20px;
        border-top: 1px solid #dedede;
        margin-top: 20px;
    }

    .app-separator {
        padding-top: 10px;
        padding-bottom: 20px;
        padding-left: 10px;
    }

    .app-body {
        background: white;
        padding: 20px 20px 10px 20px;
        margin-bottom: 10px;
    }

    .el-table .warning-row {
        --el-table-tr-bg-color: var(--el-color-warning-lighter);
    }

    .el-table .success-row {
        --el-table-tr-bg-color: var(--el-color-success-lighter);
    }

    .el-pagination {
        text-align: center;
        margin-top: 10px;
    }
</style>

