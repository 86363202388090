<template>
    <el-dialog v-model="dialogFormVisible" :title="title" @close="closeDialog">
        <el-form :model="addMessageForm" ref="addMessageRef" :rules="addMessagerules">
                       
                        <el-form-item label="内容" :label-width="formLabelWidth"
                                      prop="content">
                            <el-input
                                    v-model="addMessageForm.content"
                                    autocomplete="off"
                                    ref="name"
                                    placeholder="请填写内容"
                            ></el-input>
                        </el-form-item>
        </el-form>
        <template #footer>
      <span class="dialog-footer">
        <el-button @click="cancel()">取消</el-button>
        <el-button type="primary" @click="save()">保存</el-button>
      </span>
        </template>
    </el-dialog>
</template>

<script>
    import {ref} from "vue";
    import {httpService} from "@/utils/httpService";
    import {ElMessage, ElForm} from "element-plus";
    import {vxRule} from "@/utils/validator";

    export default {
        name: "AddMessage",
        components: {ElForm, ElMessage},
        data() {
            return {
                dialogFormVisible: ref(false),
                formLabelWidth: "120px",
                title: "",
                personnelType: "",
                addMessageForm: {
                    fromId: "",
                    toId: "",
                    content: "",
                    createTime: "",
                    status: "",
                },
                // 校验规则
                addMessagerules: {
                    content: vxRule(true, null, "blur"),
                },
            };
        },
        methods: {
            closeDialog() {
                this.clear();
            },
            cancel() {
                this.dialogFormVisible = false;
                this.clear();
            },
            getMessageById(id) {
                httpService("/api/message/queryById", {id: id}, "get").then(
                    (data) => {
                        this.addMessageForm = data;
                    }
                );
            },
            clear() {
                this.dialogFormVisible = false;
                //清空form
                    this.addMessageForm.id = "";
                    this.addMessageForm.fromId = "";
                    this.addMessageForm.toId = "";
                    this.addMessageForm.content = "";
                    this.addMessageForm.createTime = "";
                    this.addMessageForm.status = "";
            },
            save() {
                this.$refs.addMessageRef.validate((valid) => {
                    if (valid) {
                        let params = new FormData(); //创建一个form对象,必须是form对象否则后端接受不到数据
                        // params.append("fromId", this.addMessageForm.fromId);
                        // params.append("toId", this.addMessageForm.toId);
                        params.append("content", this.addMessageForm.content);
                        // params.append("createTime", this.addMessageForm.createTime);
                        // params.append("status", this.addMessageForm.status);
                        if (this.addMessageForm.id) {
                            params.append("id", this.addMessageForm.id);
                            httpService("/api/message/edit", params, "post").then(
                                (data) => {
                                    if (data.code == 200) {
                                        ElMessage.success("修改成功");
                                        this.clear();
                                        //刷新父组件的数据
                                        this.$parent.getMessageList();
                                    } else {
                                        ElMessage.error("修改失败");
                                    }
                                }
                            );
                        } else {
                            httpService("/api/message/add", params, "post").then(
                                (data) => {
                                    if (data.code == 200) {
                                        ElMessage.success("添加成功");
                                        this.clear();
                                        //刷新父组件的数据
                                        this.$parent.getMessageList();
                                    } else {
                                        ElMessage.error("添加失败");
                                    }
                                }
                            );
                        }
                    } else {
                        return false;
                    }
                });
            },
        },
    };
</script>
<style>
</style>